import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    window.setTimeout(() => {
      const $el = $('.active', this.scope.element);
      if ($el.length > 0) {
        $el.get(0).scrollIntoView({ block: 'center', inline: 'start' });
      }
    }, 0);
  }
}
