import { Controller } from "stimulus";
import bsCustomFileInput from 'bs-custom-file-input';

export default class extends Controller {
  connect() {
    if (this.data.get('i18nButton')) {
      $('head').append('<style type="text/css">.custom-file-input ~ .custom-file-label::after { content: "' + this.data.get('i18nButton') + '"; }</style>');
    }
    
    bsCustomFileInput.init();
  }
}
