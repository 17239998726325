import {CheckoutControllerBase} from "./checkout/checkout_base_controller";

export default class extends CheckoutControllerBase {

  async connect() {
    super.connect();
    this.createCard();
  }

  registerEvents() {
    super.registerEvents();
  }

  onFormSubmit(event) {
    event.preventDefault();
    if (this.cardEl?.isInvalid) {
      $('.cc .el').addClass('stripe-input-invalid is-invalid');
    } else {
      $('.cc .el').removeClass('stripe-input-invalid is-invalid').addClass('stripe-input-valid');
    }

    if (!this.$el.get(0).checkValidity() || this.cardEl.isInvalid) {
      this.$el.addClass('was-validated');
    }
    this.block();
    this.process();
  }

  createCard () {
    const elements = window.Stripe.elements();

    this.cardEl = elements.create('card', {
      classes: {
        base: 'form-control stripe-input',
        focus: 'stripe-input-focus',
        complete: 'stripe-input-valid',
        invalid: 'stripe-input-invalid is-invalid'
      },
      hideIcon: true,
      hidePostalCode: true
    });
    this.cardEl.mount('.cc .el');
    this.cardEl.isInvalid = true;

    this.cardEl.on('change', (e) => {
      this.cardEl.isInvalid = e.empty;
      $('.cc .invalid-feedback').text(e.error ? e.error.message : '');
    });
  }

  async process() {
    const intent = await $.post('/checkout/stripe', { billing_details: this.billing_details(),
      tax_rate: this.taxEvidenceCalculator.taxEvidence$.value.applied_rate,
      vat: $('#billing_vat').val()
    });
    $('#stripe_payment_intent_id').val(intent.id);
    $('#cart_encrypted').val(intent.cart_encrypted);

    const confirmation = await window.Stripe.confirmCardPayment(intent.secret, {
      payment_method: {
        card: this.cardEl,
        billing_details: this.billing_details()
      }
    });

    if (confirmation.error) {
      $('.cc .invalid-feedback').text(confirmation.error.message);
      $('.cc .el').addClass('stripe-input-invalid is-invalid');
      this.unblock();
    } else {
      await $.post(this.$el.attr('action'), this.$el.serialize());
      this.success();
    }

  }
}
