import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    if (window.localStorage.$AECookieConsent) {
      return;
    }

    $(this.scope.element).modal({
      backdrop: 'static',
      keyboard: false,
      show: true
    });
  }

  more (e) {
    e.preventDefault();
    $('#more', this.scope.element).toggleClass('d-none');
  }

  accept () {
    window.localStorage.$AECookieConsent = true;
    $(this.scope.element).modal('hide');
  }
}
