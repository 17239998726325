import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    const
      $el      = $(this.scope.element),
      $ref     = $(this.data.get('ref')),
      $overlay = $('#screen-overlay')
    ;

    $el.on('click', (e) => {
      e.preventDefault();
      e.stopPropagation();

      $overlay.toggleClass('show');
      $ref.toggleClass("show");
    });

    const close = () => {
      $overlay.removeClass('show');
      $ref.removeClass('show');
    };

    $overlay.on('click', close);
    $('[data-close]', $ref).on('click', close);
  }
}
