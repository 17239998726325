import { Controller } from 'stimulus';
import {BehaviorSubject, from, take} from 'rxjs';
import {tap} from 'rxjs/operators';

export class TaxEvidenceCalculator {

    taxEvidence$ = new BehaviorSubject(undefined);

    calculate(fields) {
        return this.fetchTaxEvidenceFor(fields)
            .pipe(
                take(1),
                tap((taxEvidence) => {
                    this.taxEvidence$.next(taxEvidence);
                })
            ).subscribe();
    }

    fetchTaxEvidenceFor(taxDetails) {
        const {postal_code, country, tax_id} = taxDetails;
        return from($.post('/cart/tax', {
            postal_code,
            country,
            tax_id
        }));
    }
}

export class CheckoutControllerBase extends Controller {
    taxEvidenceCalculator = new TaxEvidenceCalculator();
    taxEvidenceLoading$ = new BehaviorSubject(true);

    async connect() {
        this.$el = $(this.scope.element);
        this.taxEvidenceCalculator = new TaxEvidenceCalculator();

        this.registerEvents();
        this.refreshTaxDetails();
    }

    block() {
        $('[type="submit"]', this.$el).prop('disabled', true);
        $('.modal.blocker', this.$el).modal('show');
    }

    success () {
        $('.modal.blocker', this.$el).modal('hide');
        $('.modal.success', this.$el).modal('show');
    }

    unblock() {
        $('[type="submit"]', this.$el).prop('disabled', false);

        $('.modal.blocker', this.$el).modal('hide');
    }

    registerEvents() {
        this.$el.on('submit', (e) => this.onFormSubmit(e))

        this.taxEvidenceLoading$.subscribe((isLoading) => {
            isLoading ? this.setLoading() : this.unblock();
        });
        this.taxEvidenceCalculator.taxEvidence$
            .subscribe((taxEvidence) => {
                this.fillTaxDetails(taxEvidence);
                this.taxEvidenceLoading$.next(false);
            })
        $('#billing_country, #billing_vat, #billing_postal_code').on('change', () => {
            this.refreshTaxDetails();
        });
    }

    onFormSubmit(event) {
        event.preventDefault();
        this.block();
        this.process();
    }

    billing_details() {
        return {
            name: $('#billing_name').val(),
            email: $('#billing_email').val(),
            address: {
                line1: $('#billing_line1').val(),
                line2: $('#billing_line2').val(),
                postal_code: $('#billing_postal_code').val(),
                city: $('#billing_city').val(),
                country: $('#billing_country').val()
            }
        }
    }

    /** Logic goes in here.*/
    process() { }

    setLoading() {
        $('[type="submit"]', this.$el).prop('disabled', true);
        $('.taxes td:nth-child(2) span', this.$el).html('');
        $('.taxes td:nth-child(2) .spinner-border', this.$el).show();
        $('.total td:nth-child(2) span', this.$el).html('');
        $('.total td:nth-child(2) .spinner-border', this.$el).show();
    }

    fillTaxDetails(taxEvidence) {
        const
            subtotal = parseFloat(this.data.get('subtotal')),
            rate     = taxEvidence.applied_rate / 100,
            taxes    = subtotal * rate,
            total    = subtotal + taxes,
            tax_name = taxEvidence.tax_details ? taxEvidence.tax_details[0].tax_name : null;

        $('#octobat_tax_evidence_id').val(taxEvidence.id);
        $('.taxes td:nth-child(1)', this.$el).html(`Tax <span class="font-weight-normal">(${tax_name ? tax_name + ' ' : ''}${taxEvidence.applied_rate}%)</span>`);
        $('.taxes td:nth-child(2) span', this.$el).html(`$${Math.round(taxes * 100) / 100}`);
        $('.taxes td:nth-child(2) .spinner-border', this.$el).hide();
        $('.total td:nth-child(2) span', this.$el).html(`$${Math.round(total * 100) / 100}`);
        $('.total td:nth-child(2) .spinner-border', this.$el).hide();
    }

    refreshTaxDetails() {
        this.taxEvidenceLoading$.next(true);
        this.taxEvidenceCalculator.calculate(this.taxFields());
    }

    taxFields() {
        return {
            postal_code: $('#billing_postal_code').val(),
            country: $('#billing_country').val(),
            tax_id: $('#billing_vat').val()
        };
    }
}