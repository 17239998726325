import { ApplicationController } from 'stimulus-use';
import WaveSurfer from 'wavesurfer.js';
import MinimapPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.minimap.js';
import CursorPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.cursor.js';

export default class extends ApplicationController {
  static targets = ['volume'];
  connect() {
    $(this.volumeTarget).slider({
      range: "min",
      value: 50,
      min: 0,
      max: 100,
      slide: () => {
        const volume = $(this.volumeTarget).slider("value") / 100;
        this.setVolumeIcon(volume);
        this.wavesurfer.setVolume(volume);
      }
    });
  }

  playpause (e) {
    const song = e.detail;

    if (this.songId && (!e.detail.id || this.songId === song.id)) {
      this.wavesurfer.playPause();
      this.data.set('playing', this.wavesurfer.isPlaying());
      $(`[data-song-id="${this.songId}"`).attr('data-song-playing', this.wavesurfer.isPlaying());
      return;
    }

    $(this.scope.element).removeClass('d-none').addClass('d-block');

    if (this.songId) {
      this.data.set('playing', false);
      $(`[data-song-id="${this.songId}"] [data-song-waveform-preview]`).show();
      $(`[data-song-id="${this.songId}"`).attr('data-song-playing', false);
      this.wavesurfer.destroy();
    }

    this.songId = song.id;

    this.data.set('playing', 'loading');
    $(`[data-song-id="${song.id}"`).attr('data-song-playing', 'loading');

    const waveformEl = $('[data-player-info="waveform"]', this.scope.element);
    this.wavesurfer = new WaveSurfer.create({
      backend: 'MediaElement',
      waveColor: '#b4b4b4',
      container: waveformEl.get(0),
      height: waveformEl.attr('height'),
      fillParent: true,
      hideScrollbar: true,
      cursorWidth: 0,
      progressColor: window.$ArtistElements.theme.color,
      responsive: true,
      plugins: [
        MinimapPlugin.create({
          container: `[data-song-id="${song.id}"] [data-song-waveform]`,
          height: 50,
          cursorWidth: 0,
          progressColor: window.$ArtistElements.theme.color
        }),
        CursorPlugin.create()
      ]
    });

    $(`[data-song-id="${song.id}"] [data-song-waveform-preview]`).hide();

    this.wavesurfer.on('finish', () => {
      this.data.set('playing', false);
      $(`[data-song-id="${this.songId}"`).attr('data-song-playing', false);
    });

    this.wavesurfer.on('seek', () => {
      this.data.set('playing', true);
      $(`[data-song-id="${this.songId}"`).attr('data-song-playing', true);
      this.wavesurfer.play();
    });

    this.wavesurfer.load(song.url, song.waveform);
    this.wavesurfer.fireEvent('ready'); // monkey patch, will cause minimap to render
    this.wavesurfer.playPause();

    this.wavesurfer.on('ready', () => {
      this.data.set('playing', true);
      $(`[data-song-id="${song.id}"`).attr('data-song-playing', true);
    });

    this.data.set('songId', song.id);
    this.data.set('favorite', song.favorite);
    this.data.set('cart', song.cart);


    $('[data-player-info="cover"', this.scope.element).attr('src', song.coverUrl);
    $('[data-player-info="name"', this.scope.element).text(song.name);
    $('[data-player-info="genres"', this.scope.element).text(song.genres ?? ''.split(',').slice(0, 3).join(' '));
    $('[data-player-info="bpm"', this.scope.element).text(song.bpm + " BPM");
    $('[data-player-info="show-other"', this.scope.element).text(`${song.versions - 1} other versions`);
    $('[data-player-info="duration"', this.scope.element).text(song.duration);
    $('[data-player-info="download-link"]', this.scope.element).attr('href', song.downloadUrl);

    $('[data-player-info="show-other"]', this.scope.element).attr('href', song.allVersionsUrl)

    $('[data-player-info="licenses"]', this.scope.element).append(song.licenses);
    $('[data-player-info="licenses"] > a', this.scope.element).each(function () {
      $(this).attr('data-action', 'click->player#cart');
    });
  }

  favorite (e) {
    e.preventDefault();
    this.dispatch('favorite', { songId: this.data.get('songId') });
  }

  toggleFavorite (e) {
    if (e.detail.songId != this.data.get('songId')) return;

    this.data.set('favorite', e.detail.toggled);
  }

  cart (e) {
    e.preventDefault();

    const target = e.target.nodeName === 'A' ? $(e.target) : $(e.target).parents('a');
    this.dispatch('cart', { songId: this.data.get('songId'), target });
  }

  toggleCart (e) {
    $(`.dropdown-menu [data-price-id="${e.detail.priceId}"]`, this.scope.element).toggleClass('active');
    this.data.set('cart', $('.dropdown-menu .active', this.scope.element).length > 0);
  }

  setVolumeIcon(volume) {
    if (volume == 0) {
      this.volumeIcon().classList.remove('fa-volume');
      this.volumeIcon().classList.add('fa-volume-slash');
    } else {
      this.volumeIcon().classList.remove('fa-volume-slash');
      this.volumeIcon().classList.add('fa-volume');
    }
  }
  volumeIcon = () => $('[data-player-info="volume-icon"', this.scope.element).get()[0];

  toggleMute() {
    const isMuted = this.volumeIcon().classList.contains('fa-volume-slash');

    if (isMuted) {
      this.wavesurfer.setVolume(0.5);
      $(this.volumeTarget).slider("value", 50);
    } else {
      this.wavesurfer.setVolume(0.0);
      $(this.volumeTarget).slider("value", 0);
    }
    this.setVolumeIcon(this.wavesurfer.getVolume());
  }
}
