// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("jquery");
require("jquery-ui-bundle");
import 'css/application.scss';

import 'bootstrap';
import 'bootstrap-select';
import "alpinejs";

document.addEventListener("turbolinks:load", (e) => {
  $('.toast').toast('show');

  $('[data-toggle="tooltip"]').tooltip();

  (() => {
    if (window.$ArtistElements && window.$ArtistElements.googleAnalytidsId) {
      window.dataLayer = window.dataLayer || []
      function gtag() { dataLayer.push(arguments); }

      gtag('js', new Date());
      gtag('config', window.$ArtistElements.googleAnalytidsId, {
        anonymize_ip: true,
        page_location: e.data.url
      });
    }
  })();
});

import "controllers";
