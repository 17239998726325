import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    const $el = $(this.scope.element);

    if ($el.parent().hasClass('bootstrap-select')) {
      return;
    }

    $el.selectpicker({
      style: '',
      styleBase: 'form-control text-dark',
      iconBase: 'fal',
      tickIcon: 'fa-check-circle fa-lg text-primary',
      countSelectedText (n) {
        return `${n} selected`;
      }
    });
  }
}
