module.exports = (options) => {
  const toast = $('.toast.template').clone();

  let type = options.type ? options.type : 'info';
  type = type === 'error' ? 'danger' : type;

  $('.toast-body', toast).html(options.message);
  $('.toast-header .badge', toast).addClass(`badge-${type}`);
  $('.toasts').append(toast);
  
  $(toast)
    .attr('data-delay', options.delay || 2000)
    .attr('data-autohide', options.keep ? false : true)
    .removeClass('d-none')
    .toast('show')
  ;

  window.setTimeout(() => {
    $(toast).remove();
  }, (options.delay || 2000) + 1000)
}
