import { Controller } from "stimulus";

export default class extends Controller {
  connect () {
    const consent = window.localStorage.getItem('cookieConsent');

    if(consent) {
      this.hide();
    } else {
      $(this.scope.element).removeClass('d-none');
    }
  }

  hide () {
    $(this.scope.element).remove();
    window.localStorage.setItem('cookieConsent', true);
  }
}
