import { ApplicationController } from 'stimulus-use';
import $toast from '../packs/toast.js';

export default class extends ApplicationController {
  static targets = ['counter'];

  async toggle (e) {
    const { songId, toggled } = e.detail;

    await $.get('/favorites/toggle', { song_id: songId });

    const
      counter = $(this.counterTarget),
      x       = toggled ? -1 : 1,
      n       = parseInt(counter.html()) + x
    ;

    counter.html(n);

    if (n > 0) {
      counter.removeClass('d-none');
    } else {
      counter.addClass('d-none');
    }

    $toast({
      message: toggled ? 'Song removed from favorites' : 'Song added to favorites',
      type: 'info'
    });
    
    this.dispatch('toggle', { songId, toggled: !toggled });

    const favorisedSongCount = parseInt(counter.html());

    if (/^\/favorites/.test(window.location.pathname) && favorisedSongCount > 0) {
      window.location.reload();
    } else {
      window.location.href = '/';
    }
  }
}
