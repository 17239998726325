import { ApplicationController } from 'stimulus-use';
import WaveSurfer from 'wavesurfer.js';

export default class extends ApplicationController {
  connect() {
    this.waveform = JSON.parse(this.data.get('waveform'));

    this.createWavesurfer();
  }

  createWavesurfer() {
    this.wavesurfer = new WaveSurfer.create({
      backend: 'MediaElement',
      waveColor: '#b4b4b4',
      cursorColor: '#b4b4b4',
      container: $('[data-song-waveform-preview]', this.scope.element).get(0),
      height: 50,
      interact: false,
      cursorWidth: 0,
      hideScrollbar: true,
      responsive: true,
    });
    this.wavesurfer.load(this.data.get('url'), this.waveform);
  }

  playpause () {
    this.dispatch('playpause', {
      id: this.data.get('id'),
      allVersionsUrl: this.data.get('allVersionsUrl'),
      name: this.data.get('name'),
      favorite: this.data.get('favorite'),
      cart: this.data.get('cart'),
      duration: this.data.get('duration'),
      bpm: this.data.get('bpm'),
      genres: this.data.get('genres'),
      versions: this.data.get('versions'),
      url: this.data.get('url'),
      coverUrl: this.data.get('coverUrl'),
      downloadUrl: this.data.get('downloadUrl'),
      waveform: this.waveform,
      licenses: $('.dropdown-menu > *', this.scope.element).clone()
    });
  }

  favorite (e) {
    e.preventDefault();

    if (e.detail.songId && e.detail.songId != this.data.get('id')) return;

    const favorite = this.data.get('favorite') === 'true';
    this.dispatch('favorite', {
      songId: this.data.get('id'),
      toggled: favorite
    });
  }

  async trash (e) {
    e.preventDefault();
    const songId = this.data.get('id');
    const response = await $.get('/cart/delete', { song_id: songId });
    if (response == 'navigate_back') {
      window.location.href = '/';
    } else {
      location.reload()
    }

  }

  toggleFavorite (e) {
    if (e.detail.songId != this.data.get('id')) return;
    this.data.set('favorite', e.detail.toggled);
  }

  cart (e) {
    e.preventDefault();
    if (e.detail.songId && e.detail.songId != this.data.get('id')) return;

    const target = e.detail.target ? $(e.detail.target) : (e.target.nodeName === 'A' ? $(e.target) : $(e.target).parents('a'));

    this.dispatch('cart', {
      songId: this.data.get('id'),
      priceId: target.data('price-id'),
      toggled: target.hasClass('active')
    });
  }

  toggleCart (e) {
    $(`.dropdown-menu [data-price-id="${e.detail.priceId}"]`, this.scope.element).toggleClass('active');
    this.data.set('cart', $('.dropdown-menu .active', this.scope.element).length > 0);
  }

  async toggleVersions (e) {
    e.preventDefault();

    const versions = $(`[data-song-parent-id="${this.data.get('id')}"]`);

    if (versions.length > 0 ) {
      versions.toggleClass('d-none');
      return;
    }

    const targetHtml = $(e.target).html();
    $(e.target).html('Loading ...');
    const html = await $.get(`/songs/${this.data.get('id')}/versions?level=${parseInt(this.data.get('level')) + 1}&download=${this.data.get('download')}`);
    $(html).insertAfter(this.scope.element);
    $(e.target).html(targetHtml);
  }
}
