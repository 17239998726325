import {CheckoutControllerBase} from "./checkout/checkout_base_controller";
import {catchError, retry, switchMap, takeWhile, tap} from "rxjs/operators";
import {finalize, interval, from, EMPTY, Observable} from "rxjs";

export function asObservable(promise) {
    return new Observable((observer) => {
        promise.then((result) => {
            observer.next(result);
        }).catch((err) => {
            console.error(err);
        });
    });
}

export default class extends CheckoutControllerBase {

    async process() {
        const mollieOrder = from($.post('/checkout/mollie', { billing_details: this.billing_details(), tax_rate: this.taxEvidenceCalculator.taxEvidence$.value.applied_rate, vat: $('#billing_vat').val() }));

        $('#cart_encrypted').val(mollieOrder.cart_encrypted);

        mollieOrder.pipe(
            tap((order) => {
                window.open(order._links.checkout.href, '_blank');
            }),
            switchMap((order) => {
                return interval(3000)
                    .pipe(
                        switchMap(() =>
                            from($.get('/checkout/mollie/status', {id: order.id}))
                        ),
                        takeWhile(order => order.status !== 'paid'),
                        catchError(() => retry(15)),
                        finalize(() => {
                            this.success();
                        })
                    );
            })
        ).subscribe();
    }
}
